import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import Seo from '../../components/Seo'
import {
  Box,
  Container
} from '@chakra-ui/react'
import SliceZone from "../../components/SliceZone/SliceZone"
import { PrismicRichText } from "@prismicio/react"

import ChamberFormStripeWrapper from "../../components/ChamberJoinForm/ChamberFormStripeWrapper"



const JoinChamberPage = ({ data, location }) => {
  const document = data.allPrismicStaticPage.edges[0].node.data;
  const pageLocation = location.pathname;

  return (
    <Layout pageLocation={pageLocation}>
      <Seo title={document.page_title.text} />
      <Box h={{base: 2, lg: 4}} bg="sage.500" />
      <Container maxW="container.xl">
        <Box my="8">
          <PrismicRichText field={document.page_content.richText} />
        </Box>
      </Container>

      <ChamberFormStripeWrapper />


      <SliceZone sliceZone={document.body} />

    </Layout>
  )
}

export const query = graphql`
{
  allPrismicStaticPage(filter: {uid: {eq: "join"}}) {
    edges {
      node {
        _previewable
        data {
          page_title {
            text
          }
          page_content {
            richText
          }
          body {
            ... on PrismicStaticPageDataBodyNewsCarousel {
              id
              slice_type
              items {
                title
                excerpt
                button_label
                button_link {
                  url
                  target
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        quality: 8
                     )
                    }
                  }
                }
              }
            }
            ... on PrismicStaticPageDataBodyLogoCallToAction {
              id
              slice_type
              primary {
                logo {
                  alt
                  localFile {
                    publicURL
                  }
                }
                align
                content {
                  text
                  richText
                }
                button_link {
                  url
                  target
                }
                button_label
                image_area_background_color
                text_area_text_color
                text_area_background_color
                full_width_image
                button_solid_or_outline
              }
            }
            ... on PrismicStaticPageDataBodyFullImageSection {
              id
              slice_type
              primary {
                text_content {
                  richText
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                headline {
                  text
                }
                button_link {
                  url
                }
                button_label
                button_color
              }
            }
            ... on PrismicStaticPageDataBodyBlueBoxSection {
              id
              slice_type
              primary {
                section_title {
                  text
                }
              }
              items {
                link_3 {
                  url
                }
                link_2 {
                  url
                }
                link_1 {
                  url
                }
                label_3
                label_2
                label_1
                image_3 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                  alt
                }
                image_2 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
                image_1 {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicStaticPageDataBodyAdvertisement {
              id
              slice_type
              primary {
                ad_link {
                  url
                }
                desktop_ad_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                mobile_ad_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicStaticPageDataBodyTabs {
              id
              slice_type
              items {
                tab_title
                tab_content {
                  richText
                }
                chamber_member_logos {
                  document {
                    ... on PrismicLogoGrid {
                      id
                      data {
                        logo_group {
                          logo {
                            alt
                            localFile {
                              childImageSharp {
                                gatsbyImageData(placeholder: BLURRED)
                              }
                            }
                          }
                          link {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicStaticPageDataBodyStaffBoardBios {
              slice_type
              primary {
                section_title {
                  text
                }
              }
              items {
                title
                photo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        placeholder: BLURRED
                        transformOptions: {fit: COVER, cropFocus: CENTER}
                      )
                    }
                  }
                  alt
                }
                name
                bio {
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
  allPrismicNewsAndEvents(
    limit: 4
    sort: {order: DESC, fields: last_publication_date}
  ) {
    edges {
      node {
        uid
        data {
          title {
            text
          }
          exceprt
          event_date(formatString: "MMMM DD, YYYY")
          content {
            richText
          }
          featured_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  layout: FULL_WIDTH
                  transformOptions: {cropFocus: CENTER}
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        last_publication_date(formatString: "MMMM DD, YYYY")
      }
    }
  }
}
`


export default JoinChamberPage
