import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import ChamberJoinForm from './ChamberJoinForm';

//const promise = loadStripe('pk_test_51L5wTpHDvQTa23riSkBCFJ5TebTu35BALZplXLmsSwPd73R0wUCHYEzqTus485IdZrpIIFbJer58TBxByU8zEEUZ00b7Zy22Tj');
const promise = loadStripe('pk_live_51L5wTpHDvQTa23riK3btZAiCEDX2DQzKd5RalwZl1fsY9XBF8OXvm1WCJs7m58OTqvOQ6QzdSvVq430xp6PT2pWR00FxonSAUu');

export default function DonationWrapper(props) {
    return (
        <div>
            <Elements stripe={promise}>
                <ChamberJoinForm amount={props.amount} />
            </Elements>
        </div>
    )
}