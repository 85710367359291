import React, { useState, useRef, useEffect } from 'react';
import {
    Container,
    Box,
    Spinner,
    Flex,
    Select,
    Link,
    Text,
    FormLabel,
    Input,
    RadioGroup,
    Radio,
    Button,
    Textarea,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    HStack,
} from '@chakra-ui/react';
import { CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { VscWarning } from "react-icons/vsc";

import StripeLogo from '../DonateForm/stripe-logo.svg';

const schema = yup.object().shape({
    yearsInBusiness: yup.string().required('Required').nullable(),
    returningMember: yup.string().required('Required').nullable(),
    businessName: yup.string().required('Required'),
    businessContactName: yup.string().required('Required'),
    address: yup.string().required('Required'),
    city: yup.string().required('Required').nullable(),
    state: yup.string().required('Required'),
    zip: yup.string().required('Required'),
    phone: yup.string().required('Required'),
    email: yup .string()
    .required('Required')
    .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email"
    ),
    businessCategory: yup.string().required('Required').nullable(),
    businessWebsite: yup.string(),
    numberFullTimeEmployees: yup.string().required('Required').nullable(),
    numberPartTimeEmployees: yup.string().required('Required').nullable(),
    paymentAmount: yup.string().required('Required').nullable(),
});

export default function ChamberJoinForm() {

const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
});

const watchData = watch();

const [succeeded, setSucceeded] = useState(false);
const [error, setError] = useState(null);
const [processing, setProcessing] = useState('');
const [clientSecret, setClientSecret] = useState('');
const [amount, setAmount] = useState();

const stripe = useStripe();
const elements = useElements();

//console.log(watchObject)
const errorStyles = {
    color: "#bf1650",
    fontSize: "1rem",
  };
  
function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}

const scrollTop = () => {
    window.scrollTo({ 
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
  };
// create a Ref to access our form element
const formRef = useRef(null)

const stripeStyle = {
    base: {
      color: "#134053",
      fontFamily: "Open Sans, sans-serif",
      fontSize: "1rem",
    },
    invalid: {
      color: "#bf1650",
      iconColor: "#bf1650",
    },
  };
  


// Show Stripe errors if they exist
const handleChange = async (event) => {
    setError(event.error ? event.error.message : "");
}

const addCommaToAmount = (amount) => {
    if (isNaN(amount)) {
        return "";
    } else {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);

    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-business-registration', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true);
                    scrollTop();
                    setProcessing(false);
                    reset();
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};

const sendFormData = async (event) => {
              
    if (!formRef.current) {
        console.log('something went wrong with the ref')
    }

    const formData = new FormData(formRef.current)
   
    console.log(Array.from(formData.entries()))

    setProcessing(true);

    window.fetch("/.netlify/functions/stripe-business-registration", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       amount: watchData.paymentAmount,
       receipt_email: watchData.email,
       description: "Chamber of Commerce Membership",
       metadata: {
         businessName: watchData.businessName,
         businessContactName: watchData.businessContactName,
         address: watchData.address,
         city: watchData.city,
         state: watchData.state,
         zip: watchData.zip,
         phone: watchData.phone,
         businessCategory: watchData.businessCategory,
         email: watchData.email
       },
     }),
   })
   .then((res) => {
     return res.json();
   })
   .then((data) => {
     setClientSecret(data.clientSecret);
     console.log(data);
     return stripe
       .confirmCardPayment(data.clientSecret, {
         payment_method: {
           card: elements.getElement(CardElement),
         },
       })

       .then((paymentResult) => {
         if (paymentResult.error) {
           setError(`Payment failed: ${paymentResult.error.message}`);
           setProcessing(false);
         } else {
           if (paymentResult.paymentIntent.status === "succeeded") {
             setError(null);
             setProcessing(false);
             sendMailgunFormData();
           }
         }
       })
    //    .then(() => {
    //      fetch('/.netlify/functions/send-business-registration', {
    //          method: 'POST',
    //          body: formData,
    //      })
    //      .then(res => {
    //          console.log(res)
    //             if (res.status === 200) {
    //                   setSucceeded(true)
    //                   setProcessing(false)
    //                   reset()
    //               } else {
    //                   setSucceeded(false)
    //                   console.log("network error")
    //                   alert("Network error. Please try again later.")
    //               }
    //      }
    //   )
    //  }
    //  )
   })
}

const FormSuccess = () => {
    scrollTop();
    return (
        <Box my="20">
        <Alert status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          >
        <AlertIcon boxSize='40px' mr={0}/>
          <AlertTitle mb="4">
            Application submitted successfully!
            </AlertTitle>
            <AlertDescription maxWidth='lg'>
            Thank you for submitting your application.<br />
            Please check your email for a copy of your application for your records.
      </AlertDescription>
        </Alert>
      </Box>
       )
}



    return (
        <Container maxWidth="container.xl" mb="16">
            {succeeded ? <FormSuccess /> : 
            (
                <div>
                    <form id="membership-form" onSubmit={handleSubmit(sendFormData)} onChange={handleChange} ref={formRef}>
                    
                    <Flex flexWrap="wrap" w="100%">
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='yearsInBusiness'>
                                    Years in Business
                                    <RequiredSpan />
                                    <Select id='yearsInBusiness' placeholder='Please select'
                                        {...register('yearsInBusiness')}
                                    >
                                        <option value="< 1 Year">&lt; 1 Year</option>
                                        <option value="1-5 Years">1 - 5 Years</option>
                                        <option value="5 - 10 Years">5 - 10 Years</option>
                                        <option value="10+ Years">10+ Years</option>
                                    </Select>
                                    {errors.yearsInBusiness && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.yearsInBusiness?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                            <FormLabel as='legend'>Returning Member?
                                <RadioGroup mt="2">
                                    <HStack spacing='24px'>
                                    <Radio value='Yes'
                                        name="returningMember"
                                        {...register('returningMember')}>Yes</Radio>
                                    <Radio value='No'
                                        name="returningMember"
                                        {...register('returningMember')}>No</Radio>
                                    </HStack>
                                </RadioGroup>
                                    {errors.returningMember && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.returningMember?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                        </Flex>

                        <Flex flexWrap="wrap" w="100%">
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='businessName'>
                                    Business Name
                                    <RequiredSpan />
                                    <Input
                                        type="text"
                                        {...register("businessName")}
                                        autoComplete="on"
                                        />
                                    {errors.businessName && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.businessName?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='businessContactName'>
                                    Contact Name
                                    <RequiredSpan />
                                    <Input
                                        type="text"
                                        {...register("businessContactName")}
                                        autoComplete="on"
                                        />
                                    {errors.businessContactName && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.businessContactName?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                        </Flex>

                        <Box w='100%' mb="4">
                            <FormLabel htmlFor='address'>
                                Address
                                <RequiredSpan />
                                <Input
                                    type="text"
                                    {...register("address")}
                                    autoComplete="on"
                                    />
                                {errors.address && (
                                    <HStack spacing="1" style={errorStyles}>
                                        <VscWarning /> <Box>{errors.address?.message}</Box>
                                    </HStack>
                                )}
                            </FormLabel>
                        </Box>

                        <Flex flexWrap="wrap" w="100%">
                            <Box w={{base: '100%', md: '33.33%'}} mb="4">
                                <FormLabel htmlFor='city'>
                                    City
                                    <RequiredSpan />
                                    <Select id='city' placeholder='Please select'
                                        {...register('city')}>
                                        <option value="Driggs">Driggs</option>
                                        <option value="Victor">Victor</option>
                                        <option value="Tetonia">Tetonia</option>
                                        <option value="Jackson">Jackson</option>
                                        <option value="Alta">Alta</option>
                                    </Select>
                                    {errors.city && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.city?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '33.33%'}} mb="4">
                                <FormLabel htmlFor='state'>
                                    State
                                    <RequiredSpan />
                                    <Select id='state' placeholder='Please select'
                                        {...register('state')}>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </Select>
                                    {errors.state && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.state?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '33.33%'}} mb="4">
                                <FormLabel htmlFor='zip'>
                                    Zip
                                    <RequiredSpan />
                                    <Select id='zip' placeholder='Please select'
                                        {...register('zip')}>
                                        <option value="Driggs">83422</option>
                                        <option value="Victor">83455</option>
                                        <option value="Tetonia">83452</option>
                                    </Select>
                                    {errors.zip && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.zip?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                        </Flex>

                        <Flex flexWrap="wrap" w="100%">
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='businessName'>
                                    Phone Number
                                    <RequiredSpan />
                                    <Input
                                        type="text"
                                        {...register("phone")}
                                        autoComplete="on"
                                        />
                                    {errors.phone && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.phone?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='email'>
                                    Email
                                    <RequiredSpan />
                                    <Input
                                        type="text"
                                        {...register("email")}
                                        autoComplete="on"
                                        />
                                    {errors.email && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.email?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                        </Flex>

                        <Flex flexWrap="wrap" w="100%">
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='businessCategory'>
                                    Business Category
                                    <RequiredSpan />
                                    <Select id='businessCategory' placeholder='Please select'
                                        {...register('businessCategory')}>
                                        <option value="Recreation/Activities">Recreation/Activities</option>
                                        <option value="Restaurant/Food/Drink">Restaurant/Food/Drink</option>
                                        <option value="Lodging/Hospitality">Lodging/Hospitality</option>
                                        <option value="Health &amp; Wellness">Health &amp; Wellness</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Other">Other</option>
                                    </Select>
                                    {errors.businessCategory && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.businessCategory?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='businessWebsite'>
                                    Business Website
                                    <Input
                                        type="text"
                                        {...register("businessWebsite")}
                                        autoComplete="on"
                                        />
                                    {errors.businessWebsite && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.businessWebsite?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                        </Flex>

                        <Flex flexWrap="wrap" w="100%">
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                                <FormLabel htmlFor='numberFullTimeEmployees'>
                                    Number of Full-Time Employees
                                    <RequiredSpan />
                                    <Select id='numberFullTimeEmployees' placeholder='Please select'
                                        {...register('numberFullTimeEmployees')}>
                                        <option value="none">None</option>
                                        <option value="1 employee">1 employee</option>
                                        <option value="2-4 employees">2-4 employees</option>
                                        <option value="5-10 employees">5-10 employees</option>
                                        <option value="11-25 emplpoyees">11-25 emplpoyees</option>
                                        <opt5ion value="25+ employees">25+ employees</opt5ion>
                                    </Select>
                                    {errors.numberFullTimeEmployees && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.numberFullTimeEmployees?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                            <Box w={{base: '100%', md: '50%'}} mb="4">
                            <FormLabel htmlFor='numberPartTimeEmployees'>
                                    Number of Part-Time Employees
                                    <RequiredSpan />
                                    <Select id='numberPartTimeEmployees' placeholder='Please select'
                                        {...register('numberPartTimeEmployees')}>
                                        <option value="none">None</option>
                                        <option value="1 employee">1 employee</option>
                                        <option value="2-4 employees">2-4 employees</option>
                                        <option value="5-10 employees">5-10 employees</option>
                                        <option value="11-25 emplpoyees">11-25 emplpoyees</option>
                                        <opt5ion value="25+ employees">25+ employees</opt5ion>
                                    </Select>
                                    {errors.numberPartTimeEmployees && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.numberPartTimeEmployees?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>
                        </Flex>
                            
                        <Box w={{base: '100%', md: '100%'}} mb="8">
                            <FormLabel as='legend'>Payment Amount
                                <RadioGroup mt="2" onChange={setAmount} value={amount}>
                                    <HStack spacing='24px'>
                                    <Radio value='50' {...register('paymentAmount')}>$50 (1 employee)</Radio>
                                    <Radio value='150' {...register('paymentAmount')}>$150 (2-5 employees)</Radio>
                                    <Radio value='250' {...register('paymentAmount')}>$250 (5+ employees)</Radio>
                                    </HStack>
                                </RadioGroup>
                                    {errors.paymentAmount && (
                                        <HStack spacing="1" style={errorStyles}>
                                            <VscWarning /> <Box>{errors.paymentAmount?.message}</Box>
                                        </HStack>
                                    )}
                                </FormLabel>
                            </Box>

                            <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp")}
                      />
                    </Box>
                        

                            <Box mb="8">
                                <FormLabel>
                                Credit Card Information:
                                <RequiredSpan />
                                </FormLabel>
                                    <CardElement options={{ style: stripeStyle }} 
                                    />
                                    <Flex w="100%" h="100%" alignItems="center">
                                    <Box mr="1">
                                        <Text fontSize="0.9rem" mb="0">Payment securely processed by</Text>
                                    </Box>
                                    <Box>
                                        <Link href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                                        <img src={StripeLogo} alt="Stripe logo" style={{width: '2.5rem'}} />
                                        </Link>
                                    </Box>
                                </Flex>
                            </Box>

                <Box my="6">
                  <Button type="submit" textAlign="center" disabled={processing} size="lg">
                    {processing ? (
                      <Box display="flex" flexDirection="row" height="100%" alignItems="center">
                        <Box><Spinner /></Box>
                      </Box>
                    ) : succeeded ? (
                      "Submitted!"
                    ) : (
                      <>
                        Pay ${addCommaToAmount(amount)}
                      </>
                    )}
                  </Button>
              {error && (
                  <div style={errorStyles} role="alert">
                    <HStack spacing="5px">
                      <VscWarning/><p className="card-error">{error}</p> 
                    </HStack>
                  </div>
                )}
            </Box>
                    </form>
                </div>
            )
            }
        </Container>
    );
}

